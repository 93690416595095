import { Button, ButtonGroup, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "react-bootstrap";

export default function createNew() {

    return (
        <>
            <div className='mt-5'>
                <Dropdown as={ButtonGroup}>
                    <Button variant='danger'>Create New</Button>
                    <DropdownToggle split variant='danger' id="dropdown-split-basic" />
                    <DropdownMenu>
                        <DropdownItem href='/shortStory'>Short Story</DropdownItem>
                        <DropdownItem href='/longStory'>Long Story</DropdownItem>
                        <DropdownItem href='/community'>Community</DropdownItem>
                        <DropdownItem href='/video'>Video</DropdownItem>
                    </DropdownMenu>
                </Dropdown>
            </div>
        </>
    )
}