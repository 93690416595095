import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { BsEye, BsPencilSquare, BsTrash } from 'react-icons/bs';
import { string } from 'slate';

interface Item {
    id: number;
    category: string;
    sub_category?: string;
    title: string;
    author: string;
    status: string;
    // Add more properties as per your item structure
}

interface currentDraftsTableProps {
    items: Item[];
    onView: (id: number) => void;
    onEdit: (id: number) => void;
    onDelete: (id: number) => void;
    author?: string | undefined | null
}

export default function CurrentDraftsTable({ items, onView, onEdit, onDelete, author }: currentDraftsTableProps) {

    const [draftsPage, setDraftsPage] = useState(1);

    const draftsPerPage = 10;
    const pendingItems = items.filter(item => item.status === 'Pending' && (item.author === author || author === undefined));
    const indexOfLastDraft = draftsPage * draftsPerPage;
    const indexOfFirstDraft = indexOfLastDraft - draftsPerPage;
    // const currentDrafts = items.slice(indexOfFirstDraft, indexOfLastDraft);
    const currentDrafts = pendingItems.slice(indexOfFirstDraft, indexOfLastDraft);
    const totalPages = Math.ceil(pendingItems.length / draftsPerPage);

    const hasMatchingDrafts = currentDrafts.some(item => item.status === 'Pending');


    const nextDraftsPage = () => {
        // if (draftsPage < Math.ceil(items.length / draftsPerPage)) {
        //     setDraftsPage(draftsPage + 1);
        // }
        if (draftsPage < totalPages) { setDraftsPage(draftsPage + 1); }

    };

    const prevDraftsPage = () => {
        // if (draftsPage > 1) {
        //     setDraftsPage(draftsPage - 1);
        // }
        if (draftsPage > 1) { setDraftsPage(draftsPage - 1); }

    };

    return (
        <>
            {hasMatchingDrafts ? (
                <>
                    <Table hover responsive>
                        <thead className="bg-gray-200">
                            <tr>
                                <th>Category</th>
                                <th>Sub Category</th>
                                <th>Title</th>
                                <th>Author</th>
                                <th>Status</th>
                                <th>Action </th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentDrafts.map(item => (
                                item.status === 'Pending' && (author === undefined || item.author === author) && (
                                    <tr key={item.id}>
                                        <td>{item.category}</td>
                                        <td>{item.sub_category}</td>
                                        <td>{item.title}</td>
                                        <td>{item.author}</td>
                                        <td>{item.status}</td>
                                        <td>
                                            <button onClick={() => onView(item.id)} className='bg-red-600 rounded-sm text-white text-xl' data-tooltip-id='preview'><BsEye /></button>
                                            <span> </span>
                                            <button onClick={() => onEdit(item.id)} className='bg-red-600 rounded-sm text-white text-xl' data-tooltip-id='edit'><BsPencilSquare /></button>
                                            <span> </span>
                                            <button onClick={() => onDelete(item.id)} type='button' className='bg-gray-600 rounded-sm text-white text-xl' data-tooltip-id='delete'><BsTrash /></button>
                                        </td>
                                    </tr>
                                )
                            ))}
                        </tbody>
                    </Table>
                    <div className="flex justify-center items-center mt-4 space-x-4">
                        <button
                            onClick={prevDraftsPage}
                            className="px-4 py-2 rounded disabled:opacity-50 text-white bg-gray-500"
                            disabled={draftsPage === 1}
                        >
                            Previous
                        </button>
                        <button
                            onClick={nextDraftsPage}
                            className="px-4 py-2 rounded disabled:opacity-50 bg-red-500"
                            disabled={draftsPage === Math.ceil(items.length / draftsPerPage)}
                        >
                            Next
                        </button>
                    </div>
                </>) : (<p>No drafts available.</p>)}
        </>
    )
}