import { Form, FloatingLabel } from 'react-bootstrap';

interface SEOFormProps {
    seo_title: string;
    description: string;
    onSeoTitleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onDescriptionChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function SEOForm({
    seo_title,
    description,
    onSeoTitleChange,
    onDescriptionChange
}: SEOFormProps) {
    return (
        <>
            <h2 className='font-semibold text-xl mb-3'>SEO Details</h2>
            <Form.Group className="mb-3" controlId="formSeoTitle">
                <Form.Label className='text-sm mb-2'>SEO Title</Form.Label>
                <FloatingLabel controlId="floatingInput" label="SEO Title" className="mb-3">
                    <Form.Control
                        type="text"
                        placeholder="Seo Title"
                        value={seo_title}
                        className='bg-gray-200'
                        onChange={onSeoTitleChange}
                    />
                </FloatingLabel>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formSeoDescription">
                <Form.Label className='text-sm mb-2'>SEO Description</Form.Label>
                <FloatingLabel controlId="floatingInput" label="SEO Description" className="mb-3">
                    <Form.Control
                        type="text"
                        placeholder="description"
                        value={description}
                        className='bg-gray-200'
                        onChange={onDescriptionChange}
                    />
                </FloatingLabel>
            </Form.Group>
        </>
    );
}
