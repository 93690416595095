import { Button } from "react-bootstrap";
import { BsEye } from "react-icons/bs";

interface ActionBarProps {
    role: string|null;
    isSubmitting: boolean;
    isSaved: boolean;
    onSubmit: (e: React.MouseEvent<HTMLButtonElement>) => void;
    onSave: (e: React.MouseEvent<HTMLButtonElement>) => void;
    onView: (e: React.MouseEvent<HTMLButtonElement>) => void;
    
}

export default function actionBar({role, isSaved, isSubmitting, onSave, onSubmit, onView}: ActionBarProps) {
    return (
        <>
            <div className='mt-5'>
                {(role === 'Magazine' || role === 'Live') && (<Button variant='danger' type='button' onClick={onSubmit} disabled={isSubmitting}>{isSubmitting ? 'Submitting...' : 'Submit'}</Button>

                )}
                <Button variant='secondary m-2' type='button' onClick={onSave} >{isSaved ? 'Saving...' : 'Save as Draft'}</Button>
                <button type='button' data-tooltip-id='preview' className='btn btn-danger text-2xl' onClick={onView}>
                    <BsEye />
                </button>
            </div>
        </>
    )
}