import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Nav from '../../Components/base/nav';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import RichTextEditor from '../../Components/RichTextEditor'; // Ensure this import is correct
import ChooseImage from '../../Components/Modal/ChooseImage';
import Sidebar from '../../Components/base/sidebar';
import { BsEye } from 'react-icons/bs';
import { Button } from 'react-bootstrap';
import ActionBar from '../../Components/Button/ActionBar';
import ContentForm from '../../Components/Forms/ContentForm';
import SEOForm from '../../Components/Forms/SEOForm';
import StoryDetails from '../../Components/Forms/StoryDetails';
import Delete from '../../Components/Button/Delete';
import VideoForm from '../../Components/Forms/VideoForm';

export default function Video() {
    const location = useLocation();
    const id = location.state && location.state.id;
    const [url, setUrl] = useState('');
    const [sub_category, setSubCategory] = useState('');
    const [title, setTitle] = useState('');
    const [seo_title, setSeo_Title] = useState('');
    const [description, setDescription] = useState('');
    const [body, setBody] = useState('');
    const [sub_head, setSubHead] = useState('');
    const [item, setItem] = useState<any | null>(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSaved, setIsSaved] = useState(false);
    const [postID, setPostID] = useState<string | null>(null);
    const [role, setRole] = useState<string | null>(null);

    useEffect(() => {
        const id = location.state && location.state.id;
    }, []);

    const handleUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUrl(e.target.value);
    };

    const handleSubCategoryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSubCategory(e.target.value);
    };

    const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTitle(e.target.value);
    };

    const handleSeoTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSeo_Title(e.target.value);
    };

    const handleDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setDescription(e.target.value);
    };

    const handleBodyChange = (content: string) => {
        setBody(content);
    };

    const handleSubHeadChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSubHead(e.target.value);
    };

    const navigate = useNavigate();

    const autosave = async () => {
        const formData = new FormData();
        formData.append('url', url);
        formData.append('sub_category', sub_category);
        formData.append('title', title);
        formData.append('body', body);
        formData.append('seo_title', seo_title);
        formData.append('description', description);
        formData.append('sub_head', sub_head);

        try {
            const authToken = localStorage.getItem('authToken');
            if (!authToken) {
                return;
            }

            if (postID) {
                await axios.post(`https://cdn.pulp.ph/api/v1/video/save/${postID}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: `Bearer ${authToken}`,
                    }

                }
                );
            } else {
                const response = await axios.post(`https://cdn.pulp.ph/api/v1/save/video`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: `Bearer ${authToken}`,
                    }
                });
                setPostID(response.data.id);
            }

            setIsSaved(false);

        } catch (error) {
            console.error('Unable to Autosave Form!', error);
        } finally {
            setTimeout(() => {
                setIsSubmitting(false);
            }, 3000);
        }
    };

    useEffect(() => {
        const handler = setTimeout(() => {
            autosave();
        }, 2000);

        return () => {
            clearTimeout(handler);
        };
    }, [ url, sub_category, title, body, seo_title, description, sub_head ]);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsSubmitting(true);

        const formData = new FormData();
        formData.append('url', url);
        formData.append('sub_category', sub_category);
        formData.append('title', title);
        formData.append('body', body);
        formData.append('seo_title', seo_title);
        formData.append('description', description);
        formData.append('sub_head', sub_head);

        try {
            const authToken = localStorage.getItem('authToken');
            if (!authToken) {
                alert('Authentication token not found!');
                setIsSubmitting(false);
                return;
            }

            if (postID) {
                await axios.post(`https://cdn.pulp.ph/api/v1/video/${postID}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: `Bearer ${authToken}`,
                    }
                });
            } else {
                const response = await axios.post(`https://cdn.pulp.ph/api/v1/video`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: `Bearer ${authToken}`,
                    }
                });
                setPostID(response.data.id);
            }
            alert('Content Submitted Successfully !!');
            navigate('/view/post/video');
        } catch (error) {
            console.error('There was an error submitting the form!', error);
            alert(`There was an error submitting the form!, ${error}`);
        } finally {
            setTimeout(() => {
                setIsSubmitting(false);
            }, 3000);
        }
    };
    const handleSaveDraft = async () => {
        setIsSubmitting(true);

        const formData = new FormData();
        formData.append('url', url);
        formData.append('title', title);
        formData.append('sub_category', sub_category);
        formData.append('body', body);
        formData.append('seo_title', seo_title);
        formData.append('description', description);
        formData.append('sub_head', sub_head);

        try {
            const authToken = localStorage.getItem('authToken');
            if (!authToken) {
                alert('Authentication token not found!');
                setIsSubmitting(false);
                return;
            }

            if (postID) {
                await axios.post(`https://cdn.pulp.ph/api/v1/video/save/${postID}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: `Bearer ${authToken}`,
                    }
                });
            } else {
                const response = await axios.post(`https://cdn.pulp.ph/api/v1/video`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: `Bearer ${authToken}`,
                    }
                });
                setPostID(response.data.id);
            }
            alert('Draft Saved Successfully !!');
        } catch (error) {
            console.error('There was an error saving the draft!', error);
            alert(`There was an error saving the draft!, ${error}`);
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleViewVideo = async () => {
        try {
            if (postID) {
                window.open(`https://pulpmagazine.com/article/video/${postID}`, '_blank');
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        const storedRole = localStorage.getItem('role');
        setRole(storedRole);
    }, []);

    const handleDelete = async () => {
        try {
            const authToken = localStorage.getItem('authToken');
            if (!authToken) {
                // Handle the case where authToken is not available
                alert('Authentication token not found!');
                return;
            }

            const confirmDelete = window.confirm('Are you sure you want to delete this content?');
            if (!confirmDelete) {
                return; // If user cancels, do nothing
            };

            if (id) {
                await axios.delete(`https://cdn.pulp.ph/api/v1/video/${id}`, {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${localStorage.getItem('authToken')}`,
                    }
                })
            };
            alert('Content Deleted Successfully !!');// Handle success (e.g., show a success message or redirect)
            navigate('/view/post/video')
        } catch (error) {
            console.error('There was an error submitting the form!', error);
            // Handle error (e.g., show an error message)
        }
    };

    return (
        <>
                    <div className="w-full">
                        <div className='Dashboard-container flex place-content-between'>
                            <h1 className='mt-5 font-semibold'>Video Post Editor</h1>
                            <ActionBar
                                role={role}
                                onSave={handleSaveDraft}
                                onSubmit={handleSubmit}
                                onView={handleViewVideo}
                                isSubmitting={isSubmitting}
                                isSaved={isSubmitting}
                            />
                        </div>
                        <div className='container mt-5 pt-5 px-4 rounded bg-white'>
                            <VideoForm
                                url={url}
                                sub_category={sub_category}
                                title={title}
                                sub_head={sub_head}
                                body={body}
                                onUrlChange={handleUrlChange}
                                onSubCategoryChange={handleSubCategoryChange}
                                onTitleChange={handleTitleChange}
                                onSubHeadChange={handleSubHeadChange}
                                onBodyChange={handleBodyChange}
                            />

                            {(role === 'Magazine' || role ==='IT') && (<SEOForm 
                            seo_title={seo_title}
                            description={description}
                            onSeoTitleChange={handleSeoTitleChange}
                            onDescriptionChange={handleDescriptionChange}
                            />)}
                            <div className='w-full border-1 border-black rounded mb-5'> </div>

                            <StoryDetails/>

                            <Delete onDelete={handleDelete} />

                                                    {/* onClick={() => handleDelete(item.id)} */}
                        </div>
                    </div>
        </>
    );
}
