import { Button } from "react-bootstrap";

interface DeleteProps {
    onDelete: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

export default function Delete ({onDelete} : DeleteProps) {
    return (
        <>
            <Button variant='secondary m-2' type='button' onClick={onDelete} >Delete</Button>
        </>
    )
}