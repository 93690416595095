import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import Sidebar from '../../Components/base/sidebar';
import ActionBar from '../../Components/Button/ActionBar';
import SEOForm from '../../Components/Forms/SEOForm';
import Delete from '../../Components/Button/Delete';
import StoryDetails from '../../Components/Forms/StoryDetails';
import CommunityForm from '../../Components/Forms/CommunityForm';

export default function Community() {
    const location = useLocation();
    const id = location.state && location.state.id;
    const [category, setCategory] = useState('');
    const [sub_category, setSubCategory] = useState('');
    const [title, setTitle] = useState('');
    const [seo_title, setSeo_Title] = useState('');
    const [description, setDescription] = useState('');
    const [body, setBody] = useState('');
    const [sub_head, setSubHead] = useState('');
    const [photo_by, setPhotoBy] = useState('');
    const [image, setImage] = useState<File | null>(null);
    const [item, setItem] = useState<any | null>(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSaved, setIsSaved] = useState(false);
    const [role, setRole] = useState<string | null>(null);

    useEffect(() => {
        if (id) {
            fetchItem(id);
        }
    }, [id]);

    const handleCategoryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setCategory(e.target.value);
    };

    const handleSubCategoryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSubCategory(e.target.value);
    };

    const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTitle(e.target.value);
    };

    const handleSeoTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSeo_Title(e.target.value);
    };

    const handleDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setDescription(e.target.value);
    };

    const handleBodyChange = (content: string) => {
        setBody(content);
    };

    const handleSubHeadChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSubHead(e.target.value);
    };

    const handlePhotoByChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPhotoBy(e.target.value);
    };

    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            setImage(e.target.files[0]);
        }
    };

    const navigate = useNavigate();

    const fetchItem = async (id: number) => {
        try {
            const response = await axios.get(`https://cdn.pulp.ph/api/v1/communities/${id}`); // Fetch item based on ID
            const community = response.data.Community;
            if (community) {
                const { category, sub_category, title, body, seo_title, description, sub_head, photo_by } = community;
                setItem(community);
                setCategory(category);
                setSubCategory(sub_category);
                setTitle(title);
                setBody(body);
                setSeo_Title(seo_title);
                setDescription(description);
                setSubHead(sub_head);
                setPhotoBy(photo_by);
            }
        } catch (error) {
            console.error('Error fetching item:', error);
        }
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsSubmitting(true);

        const formData = new FormData();
        formData.append('category', category);
        formData.append('sub_category', sub_category);
        formData.append('title', title);
        formData.append('body', body);
        formData.append('seo_title', seo_title);
        formData.append('description', description);
        formData.append('sub_head', sub_head);
        formData.append('photo_by', photo_by);
        if (image) {
            formData.append('image', image);
        }

        try {
            const authToken = localStorage.getItem('authToken');
            if (!authToken) {
                // Handle the case where authToken is not available
                alert('Authentication token not found!');
                setIsSubmitting(false);
                return;
            }

            await axios.post(`https://cdn.pulp.ph/api/v1/community/${id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${authToken}`,
                }
            });
            alert('Content Submitted Successfully !!'); // Handle success (e.g., show a success message or redirect)
            navigate('/view/post/community');
        } catch (error) {
            console.error('There was an error submitting the form!', error);
            alert(`There was an error submitting the form!, ${error}`);
            // Handle error (e.g., show an error message)
        } finally {
            setTimeout(() => {
                setIsSubmitting(false);
            }, 3000);
        }
    };


    const handleSaveDraft = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsSaved(true);

        const formData = new FormData();
        formData.append('category', category);
        formData.append('sub_category', sub_category);
        formData.append('title', title);
        formData.append('body', body);
        formData.append('seo_title', seo_title);
        formData.append('description', description);
        formData.append('sub_head', sub_head);
        formData.append('photo_by', photo_by);
        if (image) {
            formData.append('image', image);
        }

        try {
            const authToken = localStorage.getItem('authToken');
            if (!authToken) {
                // Handle the case where authToken is not available
                alert('Authentication token not found!');
                setIsSubmitting(false);
                return;
            }

            await axios.post(`https://cdn.pulp.ph/api/v1/community/save/${id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${authToken}`,
                }
            });
            alert('Draft Saved Successfully !!'); // Handle success (e.g., show a success message or redirect)
            navigate('/view/post/community');
        } catch (error) {
            console.error('There was an error submitting the form!', error);
            alert(`There was an error submitting the form!, ${error}`);
            // Handle error (e.g., show an error message)
        } finally {
            setTimeout(() => {
                setIsSaved(false);
            }, 3000);
        }
    };

    const handleDelete = async () => {
        try {
            const authToken = localStorage.getItem('authToken');
            if (!authToken) {
                alert('Authentication token not found!');
                return;
            }

            const confirmDelete = window.confirm('Are you sure you want to delete this content?');
            if (!confirmDelete) {
                return; // If user cancels, do nothing
            }

            if (id) {
                await axios.delete(`https://cdn.pulp.ph/api/v1/community/${id}`, {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${localStorage.getItem('authToken')}`,
                    }
                })
            };
            alert('Content Deleted Successfully !!');
            navigate('/view/post/community');
        } catch (error) {
            console.error('There was an error deleting the content!', error);
            alert(`There was an error deleting the content!, ${error}`);
        }
    };



    useEffect(() => {
        const storedRole = localStorage.getItem('role');
        setRole(storedRole);
    }, []);

    const handleViewShortstory = () => {
        window.open(`https://pulpmagazine.com/article/${id}`, '_blank');
    };

    return (
        <>
                    <div className="w-full">
                        <div className='Dashboard-container flex place-content-between'>
                        <h1 className='mt-5 font-semibold'>Community Editor</h1>
                            <ActionBar
                                role={role}
                                onSave={handleSaveDraft}
                                onSubmit={handleSubmit}
                                onView={handleViewShortstory}
                                isSubmitting={isSubmitting}
                                isSaved={isSubmitting}
                            />
                        </div>
                        <div className='container mt-5 pt-5 px-4 rounded bg-white'>
                            <CommunityForm
                                category={category}
                                sub_category={sub_category}
                                title={title}
                                sub_head={sub_head}
                                body={body}
                                onCategoryChange={handleCategoryChange}
                                onSubCategoryChange={handleSubCategoryChange}
                                onTitleChange={handleTitleChange}
                                onSubHeadChange={handleSubHeadChange}
                                onBodyChange={handleBodyChange}
                                onImageChange={handleImageChange}
                            />

                            {(role === 'Magazine' || role === 'IT') && (
                                <SEOForm
                                    seo_title={seo_title}
                                    description={description}
                                    onSeoTitleChange={handleSeoTitleChange}
                                    onDescriptionChange={handleDescriptionChange}
                                />)}
                            
                            <div className='w-full border-1 border-black rounded mb-5'> </div>


                            <StoryDetails/>

                            <Delete
                            onDelete={handleDelete}
                            />
                        </div>
                    </div>
        </>
    );
}
