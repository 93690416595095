// src/Components/Dashboard/Login/index.tsx
import React, { useState } from 'react';
import axios, { AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';

export default function Login() {
    const [first_name, setFirstName] = useState('');
    const [last_name, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [role, setRole] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [confirm_password, setConfirmPassword] = useState('');
    const [error, setError] = useState<any | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const handleLogin = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsLoading(true);
        setError(null);

        try {
            const response = await axios.post('https://cdn.pulp.ph/api/v1/register', {
                first_name,
                last_name,
                username,
                email,
                role,
                password,
                confirm_password,
            });

            if (response.status === 201) {
                // Handle successful login
                console.log('Registration successful:', response.data);
                const token = response.data.token;
                localStorage.setItem('authToken', token);
                axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
                // Redirect to the dashboard
                navigate('/dashboard');
            } else {
                // Handle errors returned by the API
                setError(response.data.message || 'Registration failed');
            }
        } catch (err: unknown) {
            // Handle network errors
            if (axios.isAxiosError(err)) {
                const axiosError = err as AxiosError<any>; // Specify the response type as needed
                const errorMessage = axiosError.response?.data || 'An unexpected error occurred';
                setError(errorMessage); // Assuming setError expects a string or null
            } else {
                setError('An unexpected error occurred');
            }
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className='bg-pulp bg-contain h-screen flex justify-center items-center font-graphik'>
            <div className="w-[300px] mt-0 mb-0 m-auto p-[2rem] border-solid-[1px] bg-white p-8 rounded-lg shadow-md">
                <h2 className='text-center text-4xl font-bold mb-4'>Sign Up</h2>
                <form onSubmit={handleLogin}>
                    <div className='font-graphik'>
                        <label htmlFor="first_name" className='text-sm py-2'>First Name:</label>
                        <input
                            type="text"
                            id="first_name"
                            value={first_name}
                            className='p-2 bg-gray-200 rounded w-full'
                            onChange={(e) => setFirstName(e.target.value)}
                            required
                        />
                        <label htmlFor="last_name" className='text-sm py-2'>Last Name:</label>
                        <input
                            type="text"
                            id="last_name"
                            value={last_name}
                            className='p-2 bg-gray-200 rounded w-full'
                            onChange={(e) => setLastName(e.target.value)}
                            required
                        />
                        <label htmlFor="username" className='text-sm py-2'>Username:</label>
                        <input
                            type="text"
                            id="username"
                            value={username}
                            className='p-2 bg-gray-200 rounded w-full'
                            onChange={(e) => setUsername(e.target.value)}
                            required
                        />
                        <label htmlFor="email" className='text-sm py-2'>Email:</label>
                        <input
                            type="email"
                            id="email"
                            value={email}
                            className='p-2 bg-gray-200 rounded w-full'
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    <label htmlFor="Role" className='text-sm py-2'>Role:</label>
                    <select  
                    id="role" 
                    value={role}
                    className='p-2 bg-gray-200 rounded w-full' 
                    onChange={(e) => setRole(e.target.value)}>
                        <option value=""> Select Role</option>
                        <option value="IT">IT</option>
                        <option value="Magazine"> Pulp Magazine </option>
                        <option value="Live"> Pulp Live World </option>
                        <option value="Journal"> Freelance / Content Writer </option>
                    </select>
                    <div>
                        <label htmlFor="password" className='text-sm py-2'>Password:</label>
                        <input
                            type="password"
                            id="password"
                            value={password}
                            className='p-2 bg-gray-200 rounded w-full'
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                        <label htmlFor="confirm_password" className='text-sm py-2'>Confirm Password:</label>
                        <input
                            type="password"
                            id="confirm_password"
                            value={confirm_password}
                            className='p-2 bg-gray-200 rounded w-full'
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            required
                        />
                    </div>
                    <div className="flex justify-center mt-4">
                        <button 
                        className='bg-red-500 font-semibold text-white p-2 rounded hover:bg-red-600 w-full'
                        type="submit" 
                        disabled={isLoading}>
                            {isLoading ? 'Creating Account...' : 'Register'}
                        </button>
                    </div>
                </form>
                <p className='text-center p-2 text-sm'>Already have an account? <a href='/' className='font-semibold text-sm text-red-500 hover:text-red-600'>Login</a> </p>
                {error && (
                    <div className="error">
                        {Object.keys(error).map((key, index) => (
                            <p key={index}>{error[key]}</p>
                        ))}
                    </div>
                )}
            </div>

        </div>
    );
};
