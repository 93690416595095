import React, { useEffect, useRef, useState } from 'react';
import Sidebar from '../../Components/base/sidebar';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import CreateNew from '../../Components/Button/CreateNew';
import CurrentDashboardTable from '../../Components/Tables/CurrentDashboardTable';
import CurrentApprovalsTable from '../../Components/Tables/CurrentApprovalsTable';
import CurrentDashboardDraftsTable from '../../Components/Tables/CurrentDashBoardDraftsTable';

interface Item {
    id: number;
    category: string;
    sub_category: string;
    title: string;
    author: string;
    status: string;
    source: 'items' | 'items1' | 'items2'
    // Add more properties as per your item structure
}

export default function Dashboard() {
    const [items, setItems] = useState<Item[]>([]);
    const [items1, setItems1] = useState<Item[]>([]);
    const [items2, setItems2] = useState<Item[]>([]);
    const navigate = useNavigate();
    const [author, setAuthor] = useState<string | undefined | null>(null);
    const [role, setRole] = useState<string | null>(null);
    let isMounted = useRef(true);

    useEffect(() => {
        const storedAuthor = localStorage.getItem('author');
        const storedRole = localStorage.getItem('role');
        setAuthor(storedAuthor);
        setRole(storedRole);
    }, []);

    useEffect(() => {
        isMounted.current = true;
        const fetchData = async () => {
            try {
                const response = await axios.get('https://cdn.pulp.ph/api/v1/short-stories');
                if (isMounted.current) {
                    setItems(response.data.shortStory.data);
                };
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
        return()=> {
            isMounted.current = false;
        };

    }, []);

    useEffect(() => {
        isMounted.current = true;
        const fetchDataLongStory = async () => {
            try {
                const response = await axios.get('https://cdn.pulp.ph/api/v1/long-stories');
                if (isMounted.current) {
                    setItems1(response.data.longStory.data);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchDataLongStory();
        return () => {
            isMounted.current = false;
        };
    }, []);
    
    useEffect(() => {
        isMounted.current = true;
        const fetchDataCommunity = async () => {
            try {
                const response = await axios.get('https://cdn.pulp.ph/api/v1/communities');
                if (isMounted.current) {
                    setItems2(response.data.Community.data);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchDataCommunity();
        return () => {
            isMounted.current = false;
        };
    }, []);

    const mergedItems: Item[] = [
        ...items.map(item => ({ ...item, source: 'items' as const })),
        ...items1.map(item => ({ ...item, source: 'items1' as const })),
        ...items2.map(item => ({ ...item, source: 'items2' as const }))
    ]

    const handleEditShortstory = (id: number) => {
        navigate('/update/shortStory', { state: { id } }); // Navigate to the ShortStoryEditor without ID in the URL but pass the id through state
    };

    const handleViewShortstory = (id: number) => {
        window.open(`https://pulpmagazine.com/article/${id}`, '_blank');
    };

    const handleDelete = async (id: number) => {
        try {
            const authToken = localStorage.getItem('authToken');
            if (!authToken) {
                // Handle the case where authToken is not available
                alert('Authentication token not found!');
                return;
            }

            const confirmDelete = window.confirm('Are you sure you want to delete this content?');
            if (!confirmDelete) {
                return; // If user cancels, do nothing
            };

            await axios.delete(`https://cdn.pulp.ph/api/v1/shortStory/${id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('authToken')}`,
                }
            });
            alert('Content Deleted Successfully !!');// Handle success (e.g., show a success message or redirect)
            window.location.reload();
        } catch (error) {
            console.error('There was an error submitting the form!', error);
            // Handle error (e.g., show an error message)
        }
    };

    const handleEditLongstory = (id: number) => {
        navigate('/update/longStory', { state: { id } }); // Navigate to the ShortStoryEditor without ID in the URL but pass the id through state
    };

    const handleViewLongstory = (id: number) => {
        window.open(`https://pulpmagazine.com/article/long_story/${id}`, '_blank');
    };

    const handleDelete1 = async (id: number) => {
        try {
            const authToken = localStorage.getItem('authToken');
            if (!authToken) {
                // Handle the case where authToken is not available
                alert('Authentication token not found!');
                return;
            }

            const confirmDelete = window.confirm('Are you sure you want to delete this content?');
            if (!confirmDelete) {
                return; // If user cancels, do nothing
            };

            await axios.delete(`https://cdn.pulp.ph/api/v1/longStory/${id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('authToken')}`,
                }
            });
            alert('Content Deleted Successfully !!');// Handle success (e.g., show a success message or redirect)
            window.location.reload();
        } catch (error) {
            console.error('There was an error submitting the form!', error);
            // Handle error (e.g., show an error message)
        }
    };

    const handleEditCommunity = (id: number) => {
        navigate('/update/community', { state: { id } }); // Navigate to the ShortStoryEditor without ID in the URL but pass the id through state
    };

    const handleViewCommunity = (id: number) => {
        window.open(`https://pulpmagazine.com/community/${id}`, '_blank');
    };

    const handleDelete2 = async (id: number) => {
        try {
            const authToken = localStorage.getItem('authToken');
            if (!authToken) {
                // Handle the case where authToken is not available
                alert('Authentication token not found!');
                return;
            }

            const confirmDelete = window.confirm('Are you sure you want to delete this content?');
            if (!confirmDelete) {
                return; // If user cancels, do nothing
            };

            await axios.delete(`https://cdn.pulp.ph/api/v1/community/${id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('authToken')}`,
                }
            });
            alert('Content Deleted Successfully !!');// Handle success (e.g., show a success message or redirect)
            window.location.reload();
        } catch (error) {
            console.error('There was an error submitting the form!', error);
            // Handle error (e.g., show an error message)
        }
    };

    return (
        <React.Fragment>

            <div className="w-full">
                <div className='Dashboard-container flex place-content-between'>
                    <h2 className="mt-5 font-semibold">Dashboard</h2>
                    <CreateNew />
                </div>
                <div className="container mt-5">
                    <h3 className='font-semibold mb-1'>My Drafts</h3>

                    <CurrentDashboardDraftsTable
                        items={mergedItems}
                        onDelete={handleDelete}
                        onEdit={handleEditShortstory}
                        onView={handleViewShortstory}
                        onDelete1={handleDelete1}
                        onEdit1={handleEditLongstory}
                        onView1={handleViewLongstory}
                        onDelete2={handleDelete2}
                        onEdit2={handleEditCommunity}
                        onView2={handleViewCommunity}
                        author={author}
                    />
                </div>

                {/* <CurrentApprovalsTable 
                        items={items}
                        onDelete={handleDelete}
                        onView={handleViewShortstory}
                        /> */}

                <div className="container mt-5">
                    <h3 className='font-semibold mb-1'>My Latest Short Stories</h3>
                    <CurrentDashboardTable
                        items={items}
                        onDelete={handleDelete}
                        onEdit={handleEditShortstory}
                        onView={handleViewShortstory}
                    />
                </div>

                <div className="container mt-5">
                    <h3 className='font-semibold mb-1'>My Latest Long Stories</h3>
                    <CurrentDashboardTable

                        items={items1}
                        onDelete={handleDelete1}
                        onEdit={handleEditLongstory}
                        onView={handleViewLongstory}
                    />
                </div>

                <div className="container mt-5">
                    <h3 className='font-semibold mb-1'>My Latest Community Post</h3>
                    <CurrentDashboardTable

                        items={items2}
                        onDelete={handleDelete2}
                        onEdit={handleEditCommunity}
                        onView={handleViewCommunity}
                    />
                </div>

            </div>
        </React.Fragment>
    )
}
