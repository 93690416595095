import { Dropdown, ButtonGroup, Button, DropdownToggle, DropdownMenu, DropdownItem } from "react-bootstrap";
import Sidebar from "../../Components/base/sidebar";
import { BsGearFill, BsPlus, BsWrenchAdjustableCircle } from "react-icons/bs";
import CreateNew from "../../Components/Button/CreateNew";

export default function admin() {
    return (
        <>
                    <div className="w-full">
                        <div className='Dashboard-container flex place-content-between'>
                            <h2 className="mt-5 font-semibold">Admin Panel</h2>
                            <CreateNew />
                        </div>
                        <div className="flex justify-center items-center mt-4 space-x-4">
                            <a href="/register"> <button className='mb-1 text-white text-5xl px-5 py-5 rounded disabled:opacity-50 bg-red-500 flex justify-center items-center'>Create User <BsPlus className="ml-3" /></button></a>
                            <a href="/changePassword"><button className='mb-1 text-white text-5xl px-5 py-5 rounded disabled:opacity-50 bg-red-500 flex justify-center items-center'>Change Password <BsGearFill className="ml-3" /></button></a>
                        </div>
                    </div>
        </>
    )
}