import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { BsEye, BsPencilSquare, BsTrash } from 'react-icons/bs';
import { string } from 'slate';

interface Item {
    id: number;
    category: string;
    sub_category: string;
    title: string;
    author: string;
    status: string;
    source: 'items'| 'items1' | 'items2'
    // Add more properties as per your item structure
}

interface currentDraftsTableProps {
    items: Item[];
    onView: (id: number) => void;
    onEdit: (id: number) => void;
    onDelete: (id: number) => void;
    onView1: (id: number) => void;
    onEdit1: (id: number) => void;
    onDelete1: (id: number) => void;
    onView2: (id: number) => void;
    onEdit2: (id: number) => void;
    onDelete2: (id: number) => void;
    author?: string|undefined|null
}

export default function CurrentDashboardDraftsTable({ items, onView, onEdit, onDelete, onView1, onEdit1, onDelete1, onView2, onEdit2, onDelete2, author }: currentDraftsTableProps) {

    const [draftsPage, setDraftsPage] = useState(1);


    const hasMatchingDrafts = items.filter(item => item.status === 'Pending');



    return (
        <>
            {hasMatchingDrafts ? (
                <>
                    <Table hover responsive>
                        <thead className="bg-gray-200">
                            <tr>
                                <th>Category</th>
                                <th>Sub Category</th>
                                <th>Title</th>
                                <th>Author</th>
                                <th>Status</th>
                                <th>Action </th>
                            </tr>
                        </thead>
                        <tbody>
                            {items.map(item => (
                                item.status === 'Pending' && (author === undefined || item.author === author) && (
                                    <tr key={item.id}>
                                        <td>{item.category}</td>
                                        <td>{item.sub_category}</td>
                                        <td>{item.title}</td>
                                        <td>{item.author}</td>
                                        <td>{item.status}</td>
                                        <td>
                                            <button onClick={() => 
                                            item.source === 'items' ? onView(item.id):
                                            item.source === 'items1'? onView1(item.id):
                                            onView2(item.id)

                                            } className='bg-red-600 rounded-sm text-white text-xl' data-tooltip-id='preview'><BsEye /></button>
                                            <span> </span>
                                            <button onClick={() => 
                                            item.source === 'items'? onEdit(item.id):
                                            item.source === 'items1' ? onEdit1(item.id):
                                            onEdit2(item.id)

                                            } className='bg-red-600 rounded-sm text-white text-xl' data-tooltip-id='edit'><BsPencilSquare /></button>
                                            <span> </span>
                                            <button onClick={() => 
                                                item.source === 'items'? onDelete(item.id):
                                                item.source === 'items1'? onDelete1(item.id):
                                                onDelete2(item.id)
                                                } type='button' className='bg-gray-600 rounded-sm text-white text-xl' data-tooltip-id='delete'><BsTrash /></button>
                                        </td>
                                    </tr>
                                )
                            ))}
                        </tbody>
                    </Table>
                    
                </>) : (<p>No drafts available.</p>)}
        </>
    )
}