import React, { useEffect, useRef, useState } from 'react';
import { Button, ButtonGroup, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Table } from 'react-bootstrap';
import Sidebar from '../../Components/base/sidebar';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { BsEye, BsPencilSquare, BsTrash, BsTrashFill } from 'react-icons/bs';
import CreateNew from '../../Components/Button/CreateNew';
import CurrentDraftsTable from '../../Components/Tables/CurrentDraftsTable';
import CurrentLatestTable from '../../Components/Tables/CurrentLatestTable';

interface Item {
    id: number;
    category: string;
    sub_category: string;
    title: string;
    author: string;
    status: string;
    // Add more properties as per your item structure
}

export default function LongStory() {
    const [items, setItems] = useState<Item[]>([]);
    const navigate = useNavigate();
    const isMounted = useRef(true);

    useEffect(() => {
        isMounted.current = true;
        const fetchData = async () => {
            try {
                const response = await axios.get('https://cdn.pulp.ph/api/v1/long-stories');
                if (isMounted.current) {
                    setItems(response.data.longStory.data);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
        return () => {
            isMounted.current = false;
        };
    }, []);

    const handleEditLongstory = (id: number) => {
        navigate('/update/longStory', { state: { id } }); // Navigate to the ShortStoryEditor without ID in the URL but pass the id through state
    };

    const handleViewLongstory = (id: number) => {
        window.open(`https://pulpmagazine.com/article/long_story/${id}`, '_blank');
    };

    const handleDelete = async (id: number) => {
        try {
            const authToken = localStorage.getItem('authToken');
            if (!authToken) {
                // Handle the case where authToken is not available
                alert('Authentication token not found!');
                return;
            }

            const confirmDelete = window.confirm('Are you sure you want to delete this content?');
            if (!confirmDelete) {
                return; // If user cancels, do nothing
            };

            await axios.delete(`https://cdn.pulp.ph/api/v1/longStory/${id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('authToken')}`,
                }
            });
            alert('Content Deleted Successfully !!');// Handle success (e.g., show a success message or redirect)
            window.location.reload();
        } catch (error) {
            console.error('There was an error submitting the form!', error);
            // Handle error (e.g., show an error message)
        }
    };

    return (
        <React.Fragment>
            <div className="w-full">
                <div className='Dashboard-container flex place-content-between'>
                    <h2 className="mt-5 font-semibold">List of All Long Stories</h2>
                    <CreateNew />
                </div>

                <div className="container mt-5">
                    <h3 className='font-semibold mb-1'>Pending Long Stories</h3>
                    <CurrentDraftsTable
                        items={items}
                        onView={handleViewLongstory}
                        onEdit={handleEditLongstory}
                        onDelete={handleDelete}
                    />
                </div>

                {/* <CurrentApprovalsTable 
                        items ={items}
                        onView={handleViewShortstory}
                        onDelete={handleDelete}
                        /> */}

                <div className="container mt-5">
                    <h3 className='font-semibold mb-1'>All Published Long Stories</h3>
                    <CurrentLatestTable
                        items={items}
                        onView={handleViewLongstory}
                        onEdit={handleEditLongstory}
                        onDelete={handleDelete}
                    />
                </div>

            </div>
        </React.Fragment>
    )
}
