export default function StoryDetails() {
    return (
        <>
            <h2 className='font-semibold text-xl mb-3'>Story Details</h2>

            <h2 className='mb-5 font-semibold' data-tooltip-id='feature'>Created</h2>
            <p></p>
            <h2 className='mb-5 font-semibold' data-tooltip-id='feature'>Last Edited</h2>
            <p></p>
            <h2 className='mb-5 font-semibold' data-tooltip-id='feature'>Published</h2>
            <p></p>
        </>
    );
}
