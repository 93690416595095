// src/Components/Dashboard/Login/index.tsx
import React, { useEffect, useState } from 'react';
import axios, { AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';

export default function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState<any | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);

    try {
      const response = await axios.post('https://cdn.pulp.ph/api/login', {
        username,
        password,
      });

      if (response.status === 201) {
        // Handle successful login
        console.log('Login successful:', response.data);
        const token = response.data.token;
        const role = response.data.role;
        const author = response.data.author;
        localStorage.setItem('authToken', token);
        localStorage.setItem('role', role);
        localStorage.setItem('author', author)
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        // Redirect to the dashboard
        navigate('/dashboard');
      } else {
        // Handle errors returned by the API
        setError(response.data.message || 'Login failed');
      }
    } catch (err: unknown) {
      // Handle network errors
      if (axios.isAxiosError(err)) {
        const axiosError = err as AxiosError<any>; // Specify the response type as needed
        const errorMessage = axiosError.response?.data || 'An unexpected error occurred';
        setError(errorMessage); // Assuming setError expects a string or null
      } else {
        setError('An unexpected error occurred');
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className='bg-pulp bg-contain h-screen flex justify-center items-center font-graphik'>
      <div className="w-[300px] mt-0 mb-0 m-auto p-[2rem] border-solid-[1px] bg-white p-8 rounded-lg shadow-md">
        <h2 className='text-center text-4xl font-bold mb-4'>Login</h2>
        <form onSubmit={handleLogin}>
          <div className='font-graphik'>
            <label htmlFor="username" className='text-sm py-2'>Username:</label>
            <input
              type="text"
              id="username"
              value={username}
              className='p-2 bg-gray-200 rounded w-[100%]'
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </div>
          <div>
            <label htmlFor="password" className='text-sm py-2'>Password:</label>
            <input
              type="password"
              id="password"
              value={password}
              className='p-2 bg-gray-200 rounded w-full'
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <div className="flex justify-center mt-4">
            <button 
              type="submit" 
              disabled={isLoading}
              className='bg-red-500 font-semibold text-white p-2 rounded hover:bg-red-600 w-full'>
              {isLoading ? 'Logging in...' : 'Login'}
            </button>
          </div>
        </form>
        <p className='text-center p-2 text-sm'>Don't have an account? <a href='/register' className='font-semibold text-sm text-red-500 hover:text-red-600'>Sign Up</a> </p>
        {error && (<div className="error">
          {Object.keys(error).map((key, index) => (
            <p key={index}>{error[key]}</p>
          ))}
        </div>
        )}
      </div>
    </div>
  );
};
