import React, { useEffect, useState } from 'react';
import { Form, FloatingLabel } from 'react-bootstrap';
import RichTextEditor from '../../Components/RichTextEditor';

interface ContentFormProps {
    category: string;
    sub_category: string;
    title: string;
    sub_head: string;
    body: string;
    onCategoryChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
    onSubCategoryChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
    onTitleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onSubHeadChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onBodyChange: (content: string) => void;
    onImageChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function LongStoryForm({
    category,
    sub_category,
    title,
    sub_head,
    body,
    onCategoryChange,
    onSubCategoryChange,
    onTitleChange,
    onSubHeadChange,
    onBodyChange,
    onImageChange
}: ContentFormProps) {

    const [subCategoryOptions, setSubCategoryOptions] = useState<string[]>([]);

    // Update subCategoryOptions based on selected category
    useEffect(() => {
        if (category === 'Spotlight') {
            setSubCategoryOptions(['Music']);
        } else if (category === 'Showcase') {
            setSubCategoryOptions(['Music', 'Culture', 'MoviesTV', 'Songs & Stories']);
        } else {
            setSubCategoryOptions([]);
        }
    }, [category]);


    return (
        <>
            <h2 className='mb-5 font-semibold'>Content</h2>
            <Form.Group className="mb-3" controlId="formCategory">
                <label className='text-sm mb-2'>Category</label>
                <Form.Select aria-label="Select Category" className='bg-gray-200' value={category} onChange={onCategoryChange}>
                    <option value="">Select Category</option>
                    <option value="Spotlight">Spotlight</option>
                    <option value="Showcase">Showcase</option>
                </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formSubCategory">
                <label className='text-sm mb-2'>Sub Category</label>
                <Form.Select aria-label="Select Category" className='bg-gray-200' value={sub_category} onChange={onSubCategoryChange}>
                    <option value="">Select Sub Category</option>
                    {subCategoryOptions.map((option, index)=> (
                        <option key={index} value={option}>{option}</option>
                    ))}
                </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formTitle">
                <label className='text-sm mb-2'>Title</label>
                <FloatingLabel controlId="floatingInput" label="Title" className="mb-3">
                    <Form.Control
                        type="text"
                        placeholder="Title"
                        value={title}
                        className='bg-gray-200'
                        onChange={onTitleChange}
                    />
                </FloatingLabel>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formSubHead">
                <label className='text-sm mb-2'>Sub Head</label>
                <FloatingLabel controlId="floatingInput" label="Sub Head" className="mb-3">
                    <Form.Control
                        type="text"
                        placeholder="Sub Head"
                        value={sub_head}
                        className='bg-gray-200'
                        onChange={onSubHeadChange}
                    />
                </FloatingLabel>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formImage">
                <Form.Label className='text-sm mb-2'>Image Banner</Form.Label>
                <Form.Control type="file" onChange={onImageChange} />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBody">
                <Form.Label className='text-sm mb-2'>Body</Form.Label>
                <RichTextEditor value={body} onChange={onBodyChange} />
            </Form.Group>
        </>
    );
}
