import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import './output.css';
import Login from './Pages/Login/index';
import Dashboard from './Pages/Dashboard/index';
import Admin from './Pages/admin/admin';
import ShortStory from './Pages/ShortStoryEditor';
import NotFound from './Components/404';
import ViewShortPost from './Pages/View Short Story Post';
import LongStory from './Pages/LongStoryEditor';
import Register from './Pages/Register';
import UpdateShortStory from './Pages/ShortStoryEditor/updateShortStory';
import ViewLongPost from './Pages/View Long Post';
import UpdateLongStory from './Pages/LongStoryEditor/updateLongStory';
import ChangePassword from './Pages/admin/changepw';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import Community from './Pages/CommunityEditor';
import UpdateCommunity from './Pages/CommunityEditor/updateCommunity';
import ViewCommunityPost from './Pages/View Community Post';
import Video from './Pages/VideosEditor';
import UpdateVideo from './Pages/VideosEditor/updateVideo'
import ViewVideoPost from './Pages/View Video Post';
import Sidebar from './Components/base/sidebar';

// Private Layout (with Sidebar, etc.)
function PrivateLayout() {
  return (
    <div className='flex font-graphik'>
      <Sidebar />
      <main className="container flex justify-center bg-gray-100 h-[100vh] overflow-auto">
        <Routes>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/admin" element={<Admin />} />
          <Route path="/shortStory" element={<ShortStory />} />
          <Route path="/view/post/shortStory" element={<ViewShortPost />} />
          <Route path="/update/shortStory" element={<UpdateShortStory />} />
          <Route path="/community" element={<Community />} />
          <Route path="/update/community" element={<UpdateCommunity />} />
          <Route path="/view/post/community" element={<ViewCommunityPost />} />
          <Route path="/longStory" element={<LongStory />} />
          <Route path="/view/post/longStory" element={<ViewLongPost />} />
          <Route path="/update/longStory" element={<UpdateLongStory />} />
          <Route path="/video" element={<Video />} />
          <Route path="/view/post/video" element={<ViewVideoPost />} />
          <Route path="/update/video" element={<UpdateVideo />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </main>
    </div>
  );
}

// Public Layout (for Login, Register, etc.)
function PublicLayout() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default function App() {

  return (
    <Router>
      <ReactTooltip id="edit" place="right" content="Edit" />
      <ReactTooltip id="preview" place="right" content="Preview" />
      <ReactTooltip id="delete" place="right" content="Delete" />
      <ReactTooltip id="feature" place="right" content="Feature Coming Soon" />

      {/* Conditionally render layout based on the current route */}
      <Routes>
        <Route path="/login" element={<PublicLayout />} />
        <Route path="/" element={<PublicLayout />} />
        <Route path="/register" element={<PublicLayout />} />
        
        {/* For all other routes, render the Private Layout */}
        <Route path="*" element={<PrivateLayout />} />
      </Routes>
    </Router>
  );
}
