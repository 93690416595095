import React, { useEffect, useState } from 'react';
import { Form, FloatingLabel } from 'react-bootstrap';
import RichTextEditor from '../../Components/RichTextEditor';

interface ContentFormProps {
    sub_category: string;
    url: string;
    title: string;
    sub_head: string;
    body: string;
    onUrlChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onSubCategoryChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
    onTitleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onSubHeadChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onBodyChange: (content: string) => void;
}

export default function VideoForm({
    url,
    sub_category,
    title,
    sub_head,
    body,
    onUrlChange,
    onSubCategoryChange,
    onTitleChange,
    onSubHeadChange,
    onBodyChange,
}: ContentFormProps) {


    return (
        <>
            <h2 className='mb-5 font-semibold'>Content</h2>
            <Form.Group className="mb-3" controlId="formCategory">
                <label className='text-sm mb-2'>Sub Category</label>
                <Form.Select aria-label="Select Category" className='bg-gray-200' value={sub_category} onChange={onSubCategoryChange}>
                    <option value="">Select Sub Category</option>
                    <option value="Candid Corner">Candid Corner</option>
                    <option value="Exclusives">Exclusives</option>
                    <option value="Highlights">Highlights</option>
                    <option value="POV">POV</option>
                    <option value="Soundbites">Soundbites</option>
                </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formTitle">
                <label className='text-sm mb-2'>Title</label>
                <FloatingLabel controlId="floatingInput" label="Title" className="mb-3">
                    <Form.Control
                        type="text"
                        placeholder="Title"
                        value={title}
                        className='bg-gray-200'
                        onChange={onTitleChange}
                    />
                </FloatingLabel>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formSubHead">
                <label className='text-sm mb-2'>Sub Head</label>
                <FloatingLabel controlId="floatingInput" label="Sub Head" className="mb-3">
                    <Form.Control
                        type="text"
                        placeholder="Sub Head"
                        value={sub_head}
                        className='bg-gray-200'
                        onChange={onSubHeadChange}
                    />
                </FloatingLabel>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formSubHead">
                <label className='text-sm mb-2'>Embed Url</label>
                <FloatingLabel controlId="floatingInput" label="Url" className="mb-3">
                    <Form.Control
                        type="text"
                        placeholder="Sub Head"
                        value={url}
                        className='bg-gray-200'
                        onChange={onUrlChange}
                    />
                </FloatingLabel>
            </Form.Group>


            <Form.Group className="mb-3" controlId="formBody">
                <Form.Label className='text-sm mb-2'>Body</Form.Label>
                <RichTextEditor value={body} onChange={onBodyChange} />
            </Form.Group>
        </>
    );
}
