import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { BsEye, BsPencilSquare, BsTrash } from 'react-icons/bs';

interface Item {
    id: number;
    category: string;
    sub_category?: string;
    title: string;
    author: string;
    status: string;
    // Add more properties as per your item structure
}

interface currentLatestTableProps {
    items: Item[];
    onView: (id: number) => void;
    onEdit: (id: number) => void;
    onDelete: (id: number) => void;
}

export default function CurrentLatestTable({ items, onView, onEdit, onDelete }: currentLatestTableProps) {

    const [latestPage, setLatestPage] = useState(1);

    const latestPerPage = 10;

    const indexOfLastLatest = latestPage * latestPerPage;
    const indexOfFirstLatest = indexOfLastLatest - latestPerPage;
    // const currentLatest = items.slice(indexOfFirstLatest, indexOfLastLatest);
    const approvedItems = items.filter(item => item.status === 'Approved');
    const currentLatest = approvedItems.slice(indexOfFirstLatest, indexOfLastLatest);
    const totalPages = Math.ceil(approvedItems.length / latestPage)

    const nextLatestPage = () => {
        // if (latestPage < Math.ceil(items.length / latestPerPage)) {
        //     setLatestPage(latestPage + 1);
        // }
        if (latestPage < totalPages) { setLatestPage(latestPage + 1)}
    };

    const prevLatestPage = () => {
        // if (latestPage > 1) {
        //     setLatestPage(latestPage - 1);
        // }
    if (latestPage > 1) {setLatestPage (latestPage - 1)}
    };


    return (
        <>
            <Table hover>
                <thead className="bg-gray-200">
                    <tr>
                        <th>Category</th>
                        <th>Sub Category</th>
                        <th>Title</th>
                        <th>Author</th>
                        <th>Status</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {currentLatest.map(item => (
                        item.status === 'Approved' && (
                            <tr key={item.id}>
                                <td>{item.category}</td>
                                <td>{item.sub_category}</td>
                                <td>{item.title}</td>
                                <td>{item.author}</td>
                                <td>{item.status}</td>
                                <td>
                                    <button onClick={() => onView(item.id)} className='bg-red-600 rounded-sm text-white text-xl' data-tooltip-id='preview'><BsEye /></button>
                                    <span> </span>
                                    <button onClick={() => onEdit(item.id)} className='bg-red-600 rounded-sm text-white text-xl' data-tooltip-id='edit'><BsPencilSquare /></button>
                                    <span> </span>
                                    <button onClick={() => onDelete(item.id)} type='button' className='bg-gray-600 rounded-sm text-white text-xl' data-tooltip-id='delete'><BsTrash /></button>
                                </td>
                            </tr>
                        )
                    ))}
                </tbody>
            </Table>
            <div className="flex justify-center items-center mt-4 space-x-4">
                <button
                    onClick={prevLatestPage}
                    className="px-4 py-2 rounded disabled:opacity-50 text-white bg-gray-500"
                    disabled={latestPage === 1}
                >
                    Previous
                </button>
                <button
                    onClick={nextLatestPage}
                    className="px-4 py-2 rounded disabled:opacity-50 bg-red-500"
                    disabled={latestPage === Math.ceil(items.length / latestPerPage)}
                >
                    Next
                </button>
            </div>
        </>
    )
}